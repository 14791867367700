<template>
  <!--begin::iframe preview-->
  <b-card no-body class="card-custom" v-if="landingTemplate">
    <!--begin::landing iframe-->
    <div class="d-flex justify-content-center mt-4">
      <b-button
        class="font-weight-bolder mr-2"
        :variant="templateType == 'mail' ? 'primary' : 'outline-secondary'"
        pill
        @click="templateType = 'mail'"
        v-if="landingTemplate"
      >
        <b-icon-envelope></b-icon-envelope>
      </b-button>
      <b-button
        class="font-weight-bolder mr-2"
        :variant="templateType == 'landing' ? 'primary' : 'outline-secondary'"
        pill
        @click="templateType = 'landing'"
        v-if="mailTemplate"
      >
        <b-icon-laptop></b-icon-laptop>
      </b-button>
      <b-button
        class="font-weight-bolder"
        :variant="
          templateType == 'postlanding' ? 'primary' : 'outline-secondary'
        "
        pill
        @click="templateType = 'postlanding'"
        v-if="postlandingTemplate"
      >
        <b-icon-link45deg></b-icon-link45deg>
      </b-button>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <a :href="uri" target="_blank" class="btn btn-primary" width="auto">{{
        this.$t("generic.buttons.open_blank")
      }}</a>
    </div>
    <div style="height: 100vh; width: 100%; overflow: scroll;">
      <iframe
        style="padding: 2rem; overflow: scroll;"
        :src="uri"
        id="landingTemplateIframe"
        class="templateiframe"
        :title="uri"
        height="100%"
        width="100%"
        scrolling="yes"
      />
    </div>
    <!--end::landing iframe-->
  </b-card>
  <!--end::iframe preview-->
</template>

<script>
import { BIconLaptop, BIconEnvelope, BIconLink45deg } from "bootstrap-vue";

export default {
  name: "CampaignTemplate",
  data() {
    return {
      templateType: "mail"
    };
  },
  computed: {
    uri() {
      if (this.templateType == "landing") {
        return this.landingTemplate;
      } else if (this.templateType == "mail") {
        return this.mailTemplate;
      } else {
        return this.postlandingTemplate;
      }
    }
  },
  props: {
    landingTemplate: {
      type: String,
      required: true
    },
    mailTemplate: {
      type: String,
      required: true
    },
    postlandingTemplate: {
      type: String,
      required: true
    }
  },
  components: {
    BIconLaptop,
    BIconEnvelope,
    BIconLink45deg
  }
};
</script>

<style lang="scss">
iframe.templateiframe {
  border: 0;
  pointer-events: none;
}
</style>
