<template>
  <div class="align-self-center">
    <div
      :class="
        `avatar avatar-${size}` +
          (showChange
            ? ` image-input image-input-outline image-input-circle`
            : '')
      "
    >
      <img
        class="face image-input-wrapper"
        svg-inline
        :src="require(`@/assets/images/avatars/${actualAvatar}`)"
        alt=""
      />
      <div
        :class="`archetype arch-${familyType}`"
        v-if="showArchetype && archetypeSvg !== false"
      >
        <img
          id="svg1"
          :src="`data:image/svg+xml;base64,${archetypeSvg}`"
          type="image/svg+xml"
        />
      </div>
      <label
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="change"
        id="popover-button"
        v-if="showChange && !changing"
        @click="changing = !changing"
      >
        <i class="fa fa-pen icon-sm text-muted"></i>
      </label>
      <b-popover
        :show="defaultAvatar && !changing"
        target="popover-button"
        :noninteractive="true"
        custom-class="round-popover font-weight-boldest"
        variant="info"
        id="avatar-popover"
      >
        {{ $t("profile.avatar") }}
      </b-popover>
      <label
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="change"
        data-toggle="tooltip"
        title=""
        data-original-title="Change avatar"
        v-if="showChange && changing"
        @click="save()"
      >
        <i class="ki ki-bold-check icon-xs text-muted" v-if="changing"></i>
      </label>
      <span
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="remove"
        data-toggle="tooltip"
        title=""
        data-original-title="Remove avatar"
        v-if="changing"
        @click="next()"
      >
        <i class="ki ki-bold-arrow-next icon-xs text-muted"></i>
      </span>
      <span
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="back"
        data-toggle="tooltip"
        title=""
        data-original-title="Remove avatar"
        v-if="changing"
        @click="back()"
      >
        <i class="ki ki-bold-arrow-back icon-xs text-muted"></i>
      </span>
      <span
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="reset"
        data-toggle="tooltip"
        title=""
        data-original-title="Remove avatar"
        v-if="changing"
        @click="reset()"
      >
        <i class="ki ki-bold-close icon-xs text-muted"></i>
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Avatar",
  props: {
    size: {
      type: String,
      required: false,
      default: "xl"
    },
    showChange: {
      type: Boolean,
      required: false,
      default: false
    },
    showArchetype: {
      type: Boolean,
      required: false,
      default: true
    },
    avatar: {
      type: String,
      required: false,
      default: "000-Empty.svg"
    }
  },
  data() {
    return {
      index: 0,
      changing: false
    };
  },
  computed: {
    ...mapGetters("User", [
      "archetype",
      "archetypeSvg",
      "archetypeId",
      "user",
      "defaultAvatar",
      "avatars"
    ]),
    actualAvatar() {
      return this.changing ? this.avatars[this.index] : this.avatar;
    },
    familyType() {
      if (parseInt(this.archetypeId) >= 92 && parseInt(this.archetypeId) <= 99)
        return "creators";
      else if (
        parseInt(this.archetypeId) >= 100 &&
        parseInt(this.archetypeId) <= 107
      )
        return "defenders";
      else if (
        parseInt(this.archetypeId) >= 76 &&
        parseInt(this.archetypeId) <= 83
      )
        return "explorers";
      else if (
        parseInt(this.archetypeId) >= 84 &&
        parseInt(this.archetypeId) <= 91
      )
        return "influencers";
      else return "";
    }
  },
  methods: {
    ...mapActions("User", ["saveAvatar"]),
    next() {
      if (this.index < this.avatars.length - 1) this.index++;
      else this.index = 0;
    },
    back() {
      if (this.index > 0) this.index--;
      else this.index = this.avatars.length - 1;
    },
    save() {
      this.saveAvatar(this.actualAvatar);
      this.$emit("reset-avatar");
      this.changing = false;
    },
    reset() {
      this.$emit("reset-avatar");
      this.changing = false;
    }
  },
  watch: {
    showChange(val) {
      if (val === true) {
        this.index = _.indexOf(this.avatars, e => {
          return e === this.avatar;
        });
      }
    },
    isUserLoaded(val) {
      if (val) {
        this.index = _.indexOf(this.avatars, e => {
          return e === this.avatar;
        });
      }
    }
  },
  mounted() {
    if (this.isUserLoaded) {
      this.index = _.indexOf(this.avatars, e => {
        return e === this.avatar;
      });
    }
  }
};
</script>

<style lang="scss">
.avatar {
  position: relative;
  .face {
    width: 10rem;
    height: 10rem;
  }
  .archetype {
    width: 2.5rem;
    height: 2.5rem;
    padding: 1px;
    border-radius: 3rem;
    background-color: rgb(227, 207, 113);
    position: absolute;
    bottom: -2px;
    right: 36%;
    clip-path: polygon(0% 83%, -5% -5%, 105% 0%, 105% 83%);
    img {
      margin-top: 0px;
      margin-left: -1px;
      height: 2.4rem;
      width: 2.4rem;
    }
  }
  &.avatar-xs {
    .face {
      width: 4rem;
      height: 4rem;
    }
    .archetype {
      width: 1rem;
      height: 1rem;
      right: 38%;
      bottom: -2px;
      img {
        position: absolute;
        bottom: 8%;
        height: 0.65rem;
        width: 0.65rem;
        right: 16%;
      }
    }
  }
  &.avatar-sm {
    .face {
      width: 3rem;
      height: 3rem;
    }
    .archetype {
      width: 0.85rem;
      height: 0.85rem;
      right: 34%;
      bottom: -2px;
      img {
        position: absolute;
        bottom: 5%;
        height: 0.55rem;
        width: 0.55rem;
        right: 11%;
      }
    }
  }
  &.avatar-md {
    .face {
      width: 5.8rem;
      height: 5.8rem;
    }
    .archetype {
      width: 2rem;
      height: 2rem;
      right: 37%;
      bottom: -4px;
      img {
        width: 1.8rem;
        height: 1.8rem;
        margin-bottom: 10px;
        position: absolute;
        right: 3%;
      }
    }
  }
  &.avatar-lg {
    .face {
      width: 7.8rem;
      height: 7.8rem;
    }
    .archetype {
      width: 2rem;
      height: 2rem;
      right: 37%;
      bottom: -4px;
      img {
        width: 1.8rem;
        height: 1.8rem;
        margin-bottom: 10px;
        position: absolute;
        right: 3%;
      }
    }
  }
}
.bg-creators {
  background-color: #bda927 !important;
}
.arch-creators {
  background-color: #eae09d !important;
  border: solid 1px #bda927 !important;
  border-radius: 3rem !important;
}
.bg-defenders {
  background-color: #5d9647 !important;
}
.arch-defenders {
  background-color: #b0dc9e !important;
  border: solid 1px #5d9647 !important;
  border-radius: 3rem !important;
}
.bg-explorers {
  background-color: #4a75ac !important;
}
.arch-explorers {
  background-color: #c2daf7 !important;
  border: solid 1px #4a75ac !important;
  border-radius: 3rem !important;
}
.bg-influencers {
  background-color: #b52114 !important;
}
.arch-influencers {
  background-color: #f78484 !important;
  border: solid 1px #b52114 !important;
  border-radius: 3rem !important;
}
.image-input.image-input-circle [data-action="next"] {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.image-input.image-input-circle [data-action="back"] {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
.image-input.image-input-circle [data-action="reset"] {
  position: absolute;
  left: 5px;
  top: 5px;
}
.popover.round-popover {
  border-radius: 50px;
}
#avatar-popover.popover {
  z-index: 0 !important;
}
</style>
