<template>
  <div>
    <div class="d-flex no-wrap align-items-center justify-content-end">
      <b-button
        tag="a"
        @click="$router.push({ name: 'ListCampaigns' })"
        size="sm"
        pill
        variant="secondary"
        class="font-weight-bolder text-uppercase mr-2"
      >
        <div class="d-flex align-items-center">
          <i class="fa fa-chevron-left" />
          <span style=" white-space: nowrap">
            {{ $t("generic.buttons.back") }}
          </span>
        </div>
      </b-button>
    </div>
    <div class="d-flex flex-column justify-content-center">
      <div>
        <img
          style="max-width: 250px; min-width: 250px;"
          :src="require('@/assets/images/icons/empty-phishing.png')"
        />
      </div>
      <div class="font-size-h6 font-weight-bold text-dark-75 py-9">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyCampaign",
  props: {
    description: {
      type: String,
      required: true
    }
  }
};
</script>
